import './App.css';
import './AppMobile.css';
import './AppDarkMode.css';
import './fonts/index.css';

import {Navbar, Home, Game, CookiePolicy, TitleBar, CookieBanner, Support} from "./containers";

import React, {useEffect, useState} from "react";
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import Modal from "./containers/modal/Modal";
import {RevealGif} from "./img";
import ReactGA from "react-ga";
import {checkFirstVisit, checkScreenMode, createCookie, getCookie} from "./containers/game/functions";

const TRACKING_ID = "G-ZBHCEPNSDE"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', () => {
        // We execute the same script as before
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    });

    // Theme
    const [theme, setTheme] = useState((getCookie('screenMode') === "") ? 'light' : getCookie('screenMode'));
    const toggleTheme = () => {
        if (getCookie('screenMode') === "light" || getCookie('screenMode') === "") {
            createCookie("screenMode", "dark", "forever");
            setTheme('dark');
            document.body.classList.add('dark');
        } else {
            createCookie("screenMode", "light", "forever");
            setTheme('light');
            document.body.classList.add('light');
        }
    }

    // Modals
    let [howToOpen, setHowToOpen] = useState(false);
    let [contactOpen, setContactOpen] = useState(false);
    let [resultsOpen, setResultsOpen] = useState(false);
    let [cookieOpen, setCookieOpen] = useState(false);
    let [bannerText, setBannerText] = useState("We use cookies to personalise content, and to analyse our traffic. We also share information about your use of our site with our analytics partners who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services. You consent to our cookies if you continue to use our website.")

    let [openNav, setOpenNav] = useState(false); // sidebar

    // Game
    let [mode, setMode] = useState("");

    useEffect(() => {
        checkFirstVisit(setHowToOpen, setTheme);
        checkScreenMode();

        if (getCookie('firstVisit') === "") {
            setCookieOpen(true)
        }
    }, []);

    return (
        <div className={`App ${theme}`}>
            <Router>
                <div className="pageContainer">
                    <Navbar open={openNav} setOpen={setOpenNav} setHowToOpen={setHowToOpen}
                            contactOpen={contactOpen} setContactOpen={setContactOpen} theme={toggleTheme}/>
                    <TitleBar mode={mode} open={openNav} setOpen={setOpenNav} setResultsOpen={setResultsOpen} theme={theme}/>

                    {/* How To Modal */}
                    <Modal name="howTo" open={howToOpen} setOpen={setHowToOpen} content={
                        <div id="howToContent" style={{textAlign: 'center'}} className={`${theme}Modal ${theme}Text modal_content`}>
                            <span onClick={() => {setHowToOpen(false)}} className="close">&times;</span>
                            <h3 id="howToPlay">HOW TO PLAY</h3>
                            <p>Test your music knowledge with <b>ALBUMDLE</b>, 16 tiles get revealed 1 by 1 until you guess the
                                correct album.</p>
                            <img alt="Animated GIF of tiles slowly appearing for Parallel Lines by Blondie album cover"
                                 id="revealGif" src={RevealGif}/>
                            <p>Type your guess in the box below and see if you're correct, feel free to share your result with
                                friends or the world!</p>
                            <i>There will be a new <b>ALBUMDLE</b> every day.</i>
                        </div>
                    } >
                    </Modal>

                    {/* Contact Modal */}
                    <Modal name="contact" open={contactOpen} setOpen={setContactOpen} content={
                        <div id="contactContent" style={{textAlign: 'center'}} className={`${theme}Modal ${theme}Text modal_content`}>
                            <span onClick={() => {setContactOpen(false)}} className="close">&times;</span>
                            <h3>CONTACT</h3>
                            <p>If you have any questions or would like to report a problem, please email: <a className={`${theme}Link`}
                                href="mailto:contact@albumdle.com">contact@albumdle.com</a></p>
                        </div>
                    } >
                    </Modal>

                    <CookieBanner open={cookieOpen} setOpen={setCookieOpen} bannerText={bannerText} setBannerText={setBannerText} />

                    <div className="innerPageContainer">
                        <Routes>
                            <Route exact path="/" element={<Home theme={theme} title={"Albumdle - The Album Guessing Game"}/>} />
                            <Route exact path="/daily" element={
                                <Game setMode={setMode} mode={""} setHowToOpen={setHowToOpen}
                                      resultsOpen={resultsOpen} setResultsOpen={setResultsOpen}
                                      theme={theme} title={"Albumdle (Daily) - The Album Guessing Game"}/>
                            } />
                            <Route exact path="/endless" element={
                                <Game setMode={setMode} mode={"endless"} setHowToOpen={setHowToOpen}
                                      resultsOpen={resultsOpen} setResultsOpen={setResultsOpen}
                                      theme={theme} setCookieOpen={setCookieOpen} setBannerText={setBannerText}
                                      title={"Albumdle (Endless) - The Album Guessing Game"}/>
                            } />
                            <Route exact path="/cookie-policy" element={<CookiePolicy theme={theme} />} />
                            <Route exact path="/support" element={<Support theme={theme} />} />

                            <Route path="*" element={<Navigate to="/" />} />
                        </Routes>
                    </div>
                </div>
            </Router>
        </div>
    );
}

export default App;

import {getCookie} from "./cookies";

let midnight = new Date();
midnight.setHours(24,0,0,0);
midnight = new Date(midnight.getTime() + midnight.getTimezoneOffset() * 60 * 1000)

export function firstVisitScreenMode(setTheme) {
    if (getCookie('screenMode') === "") {
        let deviceTheme = window.matchMedia('(prefers-color-scheme: dark)').matches;

        if (deviceTheme) {
            setTheme("dark")
            document.cookie = "screenMode=dark; expires=Tue, 19 Jan 2038 03:14:07 UTC;";
        } else {
            setTheme("light")
            document.cookie = "screenMode=light; expires=Tue, 19 Jan 2038 03:14:07 UTC;";
        }

        checkScreenMode();
    }
}

export function toggleScreenMode() {
    if (getCookie('screenMode') === "light" || getCookie('screenMode') === "") {
        document.cookie = "screenMode=dark; expires=Tue, 19 Jan 2038 03:14:07 UTC;";
    } else {
        document.cookie = "screenMode=light; expires=Tue, 19 Jan 2038 03:14:07 UTC;";
    }

    checkScreenMode();
}

export function checkScreenMode() {
    let screenMode = getCookie('screenMode');
    document.body.classList.add(screenMode);
    if (screenMode === "dark") {
        document.getElementById('screenToggleBtn').textContent = 'Light Mode ☀️';
    } else {
        document.getElementById('screenToggleBtn').textContent = 'Dark Mode 🌙';
    }
}